<template>
  <div class="admin-container">
    <template v-if="timeline">
      <v-row>
        <v-col cols="12" md="12">
          <v-btn tile small @click="$router.push({ name: 'Timeline' })"
            >Zpět</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="timeline.name"
            label="Název etapy *"
            :disabled="disabled"
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="3">
          <DatePicker
            label="Předpokládaný termín zahájení *"
            :disableUser="true"
            @dateChanged="changeDateBegin($event)"
            :initialDate="
              timeline.supposed_term_of_begin
                ? timeline.supposed_term_of_begin
                : null
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <!-- label="Vložit za etapu"
            item-text="name"
            item-value="id" -->
        <v-col cols="12" md="3"
          ><v-select
            v-if="['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)"
            :items="data"
            v-model="timeline.behind_timeline"
            :disabled="disabled"
            label="Pořadí etapy"
            
          ></v-select
        ></v-col>
        <v-col cols="12" md="3">
          <DatePicker
            :disableUser="true"
            label="Předpokládaný termín dokončení *"
            @dateChanged="changeDate($event)"
            :initialDate="timeline.supposed_term_of_completion"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6"
          ><v-textarea
            v-model="timeline.note"
            :disabled="disabled"
            label="Poznámka"
            auto-grow
          ></v-textarea
        ></v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="col-secondary-3">
            Projektový manager: {{ timeline.project_manager.first_name }}
            {{ timeline.project_manager.last_name }}
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col>
          <v-btn small color="primary" tile @click="toMessages">
            <div class="d-flex flex-align-center">
              <img
                :src="require('@/assets/poslat_zpravu.svg')"
                class="icon-wrapp mr-2"
                style="width: 15px; height: 15px"
              />
              Poslat zprávu
            </div>
          </v-btn>
        </v-col>
        <v-col class="text-right" v-if="!disabled">
          <v-btn
            small
            tile
            color="error"
            class="mr-4"
            @click="deleteDialog = true"
            >Odstranit</v-btn
          >
          <v-btn
            small
            tile
            color="secondary"
            class="mr-4"
            @click="$router.push({ name: 'Timeline' })"
            >Zrušit</v-btn
          >
          <v-btn small tile color="primary" @click="update">Uložit</v-btn>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-dialog
          v-model="deleteDialog"
          :max-width="450"
          persistent
          class="rounded-0"
        >
          <v-card>
            <v-card-title class="headline">Odstranit etapu</v-card-title>
            <v-card-subtitle
              >Opravdu si přejete odstranit etapu ?</v-card-subtitle
            >
            <v-card-text>
              <v-row> </v-row>
              <v-row class="mt-6">
                <v-col class="text-center">
                  <v-btn color="error" small tile width="100%" @click="destroy"
                    >Odstranit</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <v-btn small text @click="deleteDialog = false" width="100%"
                    >Zrušit</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <h3 class="mt-10">Historie</h3>
      <v-row>
        <v-col cols="12" md="8">
          <table class="table-changes">
            <tr v-for="change in timeline.changes" :key="change.id">
              <td class="col-secondary-2">{{ change.changed_at }}</td>
              <td class="col-secondary-2">
                {{ change.editor.first_name }} {{ change.editor.last_name }}
              </td>
              <td class="col-secondary-2">{{ change.note }}</td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import bus from "../plugins/bus";
import authHandler from "../api/authHandler";
import timelineService from "../api/timelineService";
import DatePicker from "../components/DatePicker";
import errorHandler from "../api/errorHandler";
export default {
  data() {
    return {
      deleteDialog: false,
      data: [],
      timeline: null,
      disabled: false,
    };
  },
  components: {
    DatePicker,
  },
  async created() {
    bus.$emit("processing");
    if (this.user.role == "END_USER" || this.user.role == "COMPANY_CUSTOMER") {
      this.disabled = true;
    }
    await this.show();
    await this.getTimeline();
    bus.$emit("processing", false);
  },
  methods: {
    async destroy() {
      try {
        bus.$emit("processing");
        await timelineService.destroy(this.$route.params.id);
        bus.$emit("processing", false);
        this.$router.push({ name: "Timeline" });
      } catch (error) {
        bus.$emit("processing", false);
        errorHandler(error);
        console.error(error);
      }
    },
    changeDate(v) {
      this.timeline.supposed_term_of_completion = v;
    },
    changeDateBegin(v) {
      this.timeline.supposed_term_of_begin = v;
      console.debug("Begin changed");
    },
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      return building;
    },
    toMessages() {
      const selectedBuilding = this.getSelectedBuilding();
      if (
        this.user.role === "PROJECT_MANAGER" ||
        this.user.role === "SUPERUSER"
      ) {
        this.$router.push({
          name: "MessagesDetail",
          params: { id: selectedBuilding.investor.id },
          query: { timeline: this.timeline.id },
        });
      }
      if (
        this.user.role === "END_USER" ||
        this.user.role === "COMPANY_CUSTOMER"
      ) {
        this.$router.push({
          name: "MessagesDetail",
          params: { id: this.timeline.project_manager.id },
          query: { timeline: this.timeline.id },
        });
      }
    },
    async show() {
      try {
        const response = await timelineService.show(this.$route.params.id);
        console.debug("Response", response);
        this.timeline = response;
      } catch (error) {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit("snackbar", {
            text: error.response.data.error.message,
            color: "error",
          });
        }
      }
    },
    async getTimeline() {
      try {
        const data = await timelineService.index();
        // this.data = data;
        for (let i = 0; i < data.length; i++) {
          this.data.push(i + 1);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async update() {
      try {
        bus.$emit("processing");
        const response = await timelineService.update(
          this.$route.params.id,
          this.timeline
        );
        console.debug("Response", response);
        bus.$emit("processing", false);
        bus.$emit("snackbar", {
          text: "Uloženo",
          color: "success",
        });
        this.$router.push({ name: "Timeline" });
      } catch (error) {
        console.error(error);
        bus.$emit("processing", false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit("snackbar", {
            text: error.response.data.error.message,
            color: "error",
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {},
};
</script>

<style></style>
